.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: darkblue;
    color: white;
    text-align: center;
}

a:link {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}